<template>
	<!-- 一件代发，仓租，增值服务 -->
	<div v-if="'SDS'==priceKey || 'WHF001'==priceKey || 'ADDVS'==priceKey">
		<table class="simTable costTable" style="width:100%;">
			<tr class="emphasize">
				<td style="width: 10%;">{{$t('i18nn_a4eca6ff2588d791')}}</td>
				<td>{{$t('i18nn_6867f16a2ac5e825')}}</td>
				<td>{{$t('b6bf0a07fe26f74f')}}</td>
				<td style="width: 10%;">{{$t('f925d9b48d8c1d45')}}</td>
			</tr>
			<tbody>
				<tr v-for="(item2,index2) in this.priceModule" :key="index2">
					<td>
						<div>{{item2.feeTypeName}}</div>
						<el-button type="primary" size="small" icon="el-icon-plus" circle @click="addPrice(index2)"></el-button>
					</td>
					<td>
						<table class="simTable costTable" style="width:100%;">
							<tr class="emphasize">
								<td>{{$t('i18nn_fc9eb3ba06096cbe')}}</td>
								<td>{{$t('i18nn_8f7ac0044d3ded94')}}</td>
								
								<td>{{$t('i18nn_7b97262a9d0258b3')}}</td>
							</tr>
							<tr v-for="(item3,index3) in item2.priceList" :key="index3">
								<td>
									<el-input size="small" v-model="item3.title"></el-input>
								</td>
								<td>
									<el-input-number size="small" v-model="item3.buyPrice" :controls="false"
										controls-position="right"></el-input-number>
								</td>
								
								<td>
									<el-input-number size="small" v-model="item3.price" :controls="false"
										controls-position="right"></el-input-number>
									<el-button type="danger" size="mini" icon="el-icon-delete" circle @click="delPrice(index2,index3)"></el-button>

								</td>
							</tr>
						</table>
					</td>
					<td>
						{{item2.unit}}
					</td>
					<td>
						<el-input size="mini" type="textarea" :placeholder="$t('15b3627faddccb1d')" v-model="item2.remark" :maxlength="1000" show-word-limit>
						</el-input>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div v-else>
		<table class="simTable costTable" style="width:100%;">
			<thead>
				<tr class="emphasize">
					<td style="width: 10%;">{{$t('i18nn_8104a3554e1a3034')}}</td>
					<td>{{$t('i18nn_4584f1f5c7cc6e72')}}</td>
				</tr>
			</thead>
			<tbody v-if="this.priceModule">
				<tr v-for="(item,index) in this.priceModule" :key="index">
					<td>
						{{item.name}}
					</td>
					<td>
						<table class="simTable costTable" style="width:100%;">
							<tr class="emphasize">
								<td style="width: 10%;">{{$t('i18nn_a4eca6ff2588d791')}}</td>
								<td>{{$t('i18nn_6867f16a2ac5e825')}}</td>
								<td>{{$t('b6bf0a07fe26f74f')}}</td>
								<td style="width: 10%;">{{$t('f925d9b48d8c1d45')}}</td>
							</tr>
							<tbody>
								<tr v-for="(item2,index2) in item.OPList" :key="index2">
									<td>
										<div>{{item2.feeTypeName}}</div>
										<el-button type="primary" size="small" icon="el-icon-plus" circle @click="addPrice2(index,index2)"></el-button>
									</td>
									<td>
										<table class="simTable costTable" style="width:100%;">
											<tr class="emphasize">
												<td>{{$t('i18nn_fc9eb3ba06096cbe')}}</td>
												<td>{{$t('i18nn_8f7ac0044d3ded94')}}</td>
												<!-- 收货入库，转运入库 -->
												<!-- <td v-if="'SCC'==priceKey||'TSCC'==priceKey">{{$t('i18nn_6ffc5b58796093bb')}}</td> -->
												
												<td>{{$t('i18nn_7b97262a9d0258b3')}}</td>
											</tr>
											<tr v-for="(item3,index3) in item2.priceList" :key="index3">
												<!-- <td>{{item3.buyPrice}}</td>
												<td v-if="'SCC'==priceKey||'TSCC'==priceKey">
													{{item3.clacFactor}}
												</td>
												<td>
													{{item3.condition}}
												</td>
												<td>{{item3.price}}</td> -->
												<td>
													<!-- {{item3.condition}} -->
													<el-input size="small" v-model="item3.title"></el-input>
												</td>
												<td>
													<el-input-number size="small" v-model="item3.buyPrice" :controls="false"
														controls-position="right"></el-input-number>
												</td>
												<!-- <td v-if="'SCC'==priceKey||'TSCC'==priceKey">
													<el-input-number size="small" v-model="item3.clacFactor" :controls="false"
														controls-position="right"></el-input-number>
												</td> -->
												
												<td>
													<!-- {{item3.price}} -->
													<el-input-number size="small" v-model="item3.price" :controls="false"
														controls-position="right"></el-input-number>
													
													<el-button type="danger" size="mini" icon="el-icon-delete" circle @click="delPrice2(index,index2,index3)"></el-button>
												</td>

											</tr>
										</table>
									</td>
									<td>
										{{item2.unit}}
									</td>
									<td>
										<el-input size="mini" type="textarea" :placeholder="$t('15b3627faddccb1d')" v-model="item2.remark" :maxlength="1000" show-word-limit>
										</el-input>
									</td>
								</tr>
							</tbody>
						</table>
					</td>
				</tr>
			</tbody>
		</table>
	</div>

</template>
<script>
	// import whNoAllSelect from '@/components/WarehouseCenter2/components/whNoAllSelect.vue';
	// import enterpriseSelFuzzy from '@/components/WarehouseCenter2/components/enterpriseSelFuzzy.vue';
	export default {

		props: {
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			priceModule: {

			},
			priceKey: {

			}
		},
		components: {
			// enterpriseSelFuzzy,
			// whNoAllSelect,
		},
		data() {
			return {

			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.initData();
			}
		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {

		},
		methods: {
			initData() {

			},
			addPrice(index) {
				let item = this.priceModule[index];
				item.priceList.push({
					buyPrice: "",
					condition: "",
					clacFactor: "",
					title:"",
					price: ""
				});
				this.$set(this.priceModule, index, item);
			},
			delPrice(index, index2) {
				let item = this.priceModule[index];
				item.priceList.splice(index2, 1);
				this.$set(this.priceModule, index, item);
			},
			addPrice2(index,index2) {
				let item = this.priceModule[index];
				item.OPList[index2].priceList.push({
					buyPrice: "",
					condition: "",
					clacFactor: "",
					title:"",
					price: ""
				});
				this.$set(this.priceModule, index, item);
			},
			delPrice2(index, index2,index3) {
				let item = this.priceModule[index];
				item.OPList[index2].priceList.splice(index3, 1);
				this.$set(this.priceModule, index, item);
			},
			getPriceData() {
				return this.priceModule;
			},


		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.costTable {
		width: 100%;
		font-size: 14px;
		line-height: 180%;

		tr {

			td,
			th {
				padding: 0;
				text-align: center;
			}
		}
	}

	.emphasize {
		background-color: #e2eef5;
		color: #333;
		font-size: 16px;
		font-weight: bold;
		line-height: 220%;
	}
</style>