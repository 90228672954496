<template>
	<el-drawer :wrapperClosable="false" :title="$t('i18nn_d5942292f805c023')" append-to-body :visible.sync="dialogFile" :direction="'rtl'"
		size="1000px">
		<div style="padding: 10px;">
			<el-form ref="form" inline :rules="formRules" :model="form" label-width="100px" v-loading="loading">
				<!-- <el-form-item :label="$t('i18nn_1145acd2ac058642')" prop="">
					<enterpriseSelFuzzy :disabled="!!form.id" ref="enterpriseSelFuzzy" @changeData="changCus2"></enterpriseSelFuzzy>
				</el-form-item> -->

				<el-form-item :label="$t('c944a6686d996ab3')" prop="" required>
					<whNoAllSelect :disabled="!!form.id" ref="whNoAllSelect2" @changeData="changWhNo2" :width="200"></whNoAllSelect>
				</el-form-item>

				<el-form-item :label="$t('Storage.tableColumn.remark')" prop="">
					<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark" :maxlength="1000" show-word-limit></el-input>
				</el-form-item>

				<el-tabs v-model="activeName">
					<el-tab-pane :label="$t('hytxs0000053')" name="first">
						<EnterperiseWhPriceTem :priceModule="form.priceObj.SCC" :priceKey="'SCC'"></EnterperiseWhPriceTem>
					</el-tab-pane>
					<el-tab-pane :label="$t('684a2afb069b6016')" name="second">
						<EnterperiseWhPriceTem :priceModule="form.priceObj.SDS" :priceKey="'SDS'"></EnterperiseWhPriceTem>
					</el-tab-pane>
					<el-tab-pane :label="$t('i18nn_6373cb1d1204d580')" name="fourth">
						<EnterperiseWhPriceTem :priceModule="form.priceObj.STF" :priceKey="'STF'"></EnterperiseWhPriceTem>
					</el-tab-pane>
					<el-tab-pane :label="$t('i18nn_1f1bd51a9a045025')" name="fifth">
						<EnterperiseWhPriceTem :priceModule="form.priceObj.TSCC" :priceKey="'TSCC'"></EnterperiseWhPriceTem>
					</el-tab-pane>
					<el-tab-pane :label="$t('i18nn_65fe75ea422bdc50')" name="sixth">
						<EnterperiseWhPriceTem :priceModule="form.priceObj.STR" :priceKey="'STR'"></EnterperiseWhPriceTem>
					</el-tab-pane>
					<el-tab-pane :label="$t('i18nn_5ddc19a148f5e5c1')" name="ten">
						<EnterperiseWhPriceTem :priceModule="form.priceObj.SRE" :priceKey="'SRE'"></EnterperiseWhPriceTem>
					</el-tab-pane>
					
					<el-tab-pane :label="$t('i18nn_5151fd7875058ddf')" name="seven">
						<EnterperiseWhPriceTem :priceModule="form.priceObj.WHF001" :priceKey="'WHF001'"></EnterperiseWhPriceTem>
					</el-tab-pane>
					<el-tab-pane :label="$t('i18nn_f80dedf7c601059f')" name="nine">
						<EnterperiseWhPriceTem :priceModule="form.priceObj.ADDVS" :priceKey="'ADDVS'"></EnterperiseWhPriceTem>
					</el-tab-pane>
					
					
				</el-tabs>




			</el-form>
		</div>

		<div class="drawer-footer">
			<el-button type="primary" icon="el-icon-tickets" @click="submitForm('form')">{{ $t('FormMsg.Save') }}</el-button>
			<!-- <el-button type="primary" plain @click="dialogFile = false">{{$t('4e9fc68608c60999')}}</el-button> -->
		</div>
	</el-drawer>
</template>
<script>
	import whNoAllSelect from '@/components/WarehouseCenter2/components/whNoAllSelect.vue';
	// import enterpriseSelFuzzy from '@/components/WarehouseCenter2/components/enterpriseSelFuzzy.vue';
	
	import EnterperiseWhPriceTem from '@/components/WarehouseCenter2/Deal/EnterperiseWhPriceTem.vue';
	
	export default {

		props: {
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			id:{}
		},
		components: {
			// AccountUserList,
			// enterpriseSelFuzzy,
			whNoAllSelect,
			EnterperiseWhPriceTem
			// dialogWarehouse
			// whNoSelect
		},
		data() {
			return {
				dialogFile: false,
				activeName: 'first',
				// loadingUpload: false,
				loading: false,
				form: {
					"enterpriseId": "",
					"whId": "",
					"whNo": "",
					"priceObj": {},
					remark: ''
				},
				// form: {
				// 	// id: null, //"数据ID",
				// 	enterpriseId: '', //
				// 	// userName:'',
				// 	// whNos: [], //
				// 	whNoArr:[],
				// 	remark: ''
				// },

				formRules: {
					enterpriseId: [{
						required: true,
						message: this.$t('FormMsg.Please_select'),
						trigger: 'change'
					}],
					// whNos: [{ required: true, message: this.$t('FormMsg.Please_select'), trigger: 'change' }]
				},
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.dialogFile = true;
				this.initData();
			}
		},
		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.initData();
		},
		methods: {
			initData() {
				// let form = Object.assign({}, formParm);
				// console.log('form', form);
				// // 重置
				this.resetForm('form');
				// if (null === formParm) {
				//新增
				this.form.enterpriseId = '';
				this.$nextTick(() => {
					// this.$refs.enterpriseSelFuzzy.clearData();
					this.$refs.whNoAllSelect2.init('');
				});
				// this.form.userName = '';
				// this.form.whNoArr = [];
				// this.form.whNos = [];
				// this.form.whNoArr = [];
				this.form.id = null;
				this.form.whId = null;
				this.form.whNo = null;
				this.form.priceObj = {};
				this.form.remark = '';

				// this.dialogFormStatus = 0;
				if(this.id){
					this.getDetData()
				} else {
					this.getTemData();
				}
				
			},
			changCus2(data) {
				console.log('changCus', data);

				this.form.enterpriseId = data.id;
				// this.filterData.userName = data.companyName;
				// this.initData();
			},
			changWhNo2(data) {
				console.log('changWhNo2', data);
				this.form.whId = data.id;
				this.form.whNo = data.code;
				// this.filterData.userName = data.companyName;
				// this.initData();
			},
			getTemData() {
				this.loading = true;

				this.$http
					.get(this.$urlConfig.HyEntWhPriceTmp, {})
					.then(({
						data
					}) => {
						console.log(data);
						this.loading = false;
						if (data.data) {
							this.form.priceObj = data.data;
						}

					})
					.catch(error => {
						console.log(error);
						this.loading = false;
					});
			},
			getDetData(){
				// let _this = this;
				this.loading = true;
				this.$http
					.get(this.$urlConfig.HyEntWhPriceDet+"/"+this.id, {})
					.then(({ data }) => {
						// console.log('分页，请求成功');
						console.log(data);
						this.loading = false;
						if(data.data){
							this.form = data.data;
							// try{
							// 	this.form.priceObj = JSON.parse(this.form.priceJson);
							// }catch(e){
							// 	//TODO handle the exception
							// 	console.log(e);
							// }
							this.$nextTick(() => {
								// this.$refs.enterpriseSelFuzzy.init(this.form.enterpriseId);
								this.$refs.whNoAllSelect2.init(this.form.whNo);
							});
						}
					})
					.catch(error => {
						console.log(error);
						// console.log('分页，请求失败');
						this.loading = false;
					});
			},
			//提交信息
			submitForm(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						// let formData = [];
						let formData = Object.assign({}, this.form);
						// console.log('formData', formData);
							this.postData(this.$urlConfig.HyEntWhPriceAdd, formData, '', () => {
								this.dialogFile = false;
								this.$emit('success');
							});
					} else {
						console.log('error submit!!');
						this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('204ffab8a6e01870'),
						});
						return false;
					}
				});
			},
			//重置输入框
			resetForm(formName) {
				console.log(formName);
				if (this.$refs[formName]) {
					this.$refs[formName].resetFields();
				} else {
					console.log('this.$refs[formName]', this.$refs[formName]);
				}
			},

			//提交信息
			postData(url, formData, type, callback) {
				// let _this = this;
				this.loading = true;
				// this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				// formData.state = formData.state ? '0' : '1';
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('tips.submitSuccess'));
					console.log(data);
					
					this.loading = false;
					// this.loading_load = false;
					if (200 == data.code) {
						// this.dialogFormVisible = false;
						// // this.getPageData();
						// // this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
						// //   type: 'success',
						// //   //confirmButtonText: this.$t('204ffab8a6e01870'),
						// // });
						// // this.$message.success(this.$t('tips.submitSuccess'));
						// if ('delete' == type) {
						// 	this.$message({
						// 		type: 'success',
						// 		message: this.$t('tips.successfullyDelete')
						// 	});
						// } else {
						// 	// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
						// 	// 	type: 'success'
						// 	// });
						// 	this.$message.success(this.$t('tips.submitSuccess'));
						// }
						callback();
					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					// this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('204ffab8a6e01870'),
					});
				});
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.costTable {
		width: 100%;
		font-size: 14px;
		line-height: 180%;
		tr {
			td,th {
				padding:0;
				text-align: center;
			}
		}
	}
	.emphasize {
		background-color: #e2eef5;
		color: #333;
		font-size: 16px;
		font-weight: bold;
		line-height: 220%;
	}
</style>